<template>
  <!-- 数据统计PC=》登录（陈） -->
  <div class="login-container pull-height" id="login">
    <div class="login-container-bg">
      <div class="login-container-bg-img"></div>
      <div class="login-box">
        <div>
          <div class="login-logo">
            <img
              src="./img/win.png"
              alt=""
              style="width: 80px; height: 80px; border-radius: 10px"
            />
            <div class="login-logo-right">
              <p style="font-size: 30px; margin-bottom: 9px">
                Win生活后台管理平台
              </p>
              <p style="font-size: 18px; color: #9ba3b6">
                Win Life APP backend management platform
              </p>
            </div>
          </div>
          <div class="width-input">
            <van-form
              class="login-form"
              status-icon
              ref="loginForm"
              :model="loginForm"
              label-width="100"
            >
              <van-cell-group inset>
                <van-field
                  v-model="loginForm.username"
                  label="用户名"
                  placeholder="请输入用户名"
                  :rules="[{ required: true, message: '请填写用户名' }]"
                  right-icon="manager-o"
                />
                <van-field
                  v-model="loginForm.password"
                  :type="passwordType"
                  label="密码"
                  placeholder="请输入密码"
                  :rules="[{ required: true, message: '请填写密码' }]"
                >
                 <template #button>
                   <van-icon name="eye-o"  @click="passwordLook"/>
                 </template>
                 </van-field>
              </van-cell-group>

              <van-cell-group inset>
                <van-button
                  type="primary"
                  size="medium"
                  :disabled="disabled"
                  style="width: 400px; margin-top: 20px; background: #2e71fe"
                  @click="handleLogin"
                  >{{ disabled ? "登 录 中..." : "登 录" }}</van-button
                >
              </van-cell-group>
            </van-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
export default {
  name: "userlogin",
  data() {
    return {
      isPay: false,
      thirdPartyForm: {
        code: "",
        state: "",
      },
      loginForm: {
        username: "",
        password: "",
        code: "",
        redomStr: "",
      },
      checked: false,
      code: {
        src: "/code",
        value: "",
        len: 4,
        type: "image",
      },

      passwordType: "password",
      sheBeiId: "", // 设备编码
      UserName: "", // 设备用户名
      disabled: false,
      
    };
  },
  created() {
    this.refreshCode();
  },
  mounted() {
    // this.getshebei()
    // this.getyonghuming()
  },
  computed: {},
  methods: {
    getshebei() {},

    getyonghuming() {},

    refreshCode() {},
    showPassword() {},
    passwordLook(){
      this.passwordType=='password'?this.passwordType='':this.passwordType='password'
    },
    handleLogin() {
      // this.$router.push("dataStatistics");
      console.info(this.loginForm.username)
       console.info(this.loginForm.password)
      if(this.loginForm.username!='15370938315'){
          Toast.fail("请输入正确的账号");
return
      }
       if(this.loginForm.password!='123890'){
              Toast.fail("请输入正确的密码");
return
      }
      this.$router.push("dataStatistics");
    },
    getFaceResult() {},
  },
};
</script>

<style lang="less">
#login {
  .van-cell {
    display: block;
  }
}
.account-card {
  font-size: 14px;
  margin: 5px 10px;
  width: 160px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px #ddd;
}

.account {
  margin-top: 10px;
}

.is-bottom {
  font-size: 12px;
}

.login-weaper {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 500px;
  margin-top: -200px;
}

.login-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.login-container::before {
  z-index: -1024;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.login-container-bg {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  &-img {
    flex: 1;
    background-image: url("./img/beijing.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.login-container-bg::before {
  z-index: -1024;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 95%;
}

.login-logo {
  // width: 150px;
  // height: 150px;
  // background-image: url("/img/bg/login_logo.png");
  // background-size: cover;
  // margin: 100px 1000px;
  margin-bottom: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  &-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.login-box {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-border {
  margin-top: 50px;
  width: 50%;
  float: left;
  box-sizing: border-box;
}

.login-main {
  margin: 0 auto;
  width: 50%;
  box-sizing: border-box;
}

.login-main > h3 {
  margin-bottom: 20px;
}

.login-main > p {
  color: #76838f;
}

.login-title {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  letter-spacing: 4px;
}

.login-menu {
  width: 100%;
  text-align: center;

  a {
    color: rgb(153, 153, 153);
    font-size: 12px;
    margin: 0px 8px;
  }
}

.login-index {
  margin-top: 40px !important;
  width: 180px;
  height: 48px;
  text-align: center;
  border-radius: 50px !important;
  background: transparent !important;
  color: #333 !important;
  font-size: 16px !important;
  border: 1px solid rgb(152, 22, 244);
}

.login-submit {
  display: block !important;
  margin: 40px auto 10px auto !important;
  width: 200px;
  height: 48px;
  font-size: 14px !important;
  text-align: center;
  border-radius: 50px !important;
  border: 0px;
  box-shadow: rgba(152, 22, 244, 0.19) 0px 5px 10px 2px;
}

.login-form {
  margin: 10px 0;

  i {
    color: #999;
  }

  .el-form-item__content {
    width: 100%;
  }

  .el-form-item {
    margin-bottom: 12px;
  }

  .el-input {
    input {
      text-indent: 5px;
      background: transparent;
      border: none;
      border-radius: 0;
      color: #333;
      border-bottom: 1px solid rgb(235, 237, 242);
    }

    .el-input__prefix {
      i {
        padding: 0 5px;
        font-size: 16px !important;
      }
    }
  }
}

.login-code {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0 0 10px;
}

.login-code-img {
  margin-top: 2px;
  width: 100px;
  height: 38px;
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 5px;
  line-height: 38px;
  text-indent: 5px;
  text-align: center;
}

.login-select {
  input {
    color: #333;
    font-size: 18px;
    font-weight: 400;
    border: none;
    text-align: center;
  }
}

.login-page-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 48px;
  color: white;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 5px;
}

.login-title2 {
  height: 35px;
  color: black;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0;
}

.login-bg {
  height: 100%;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
